import React, { useState, useEffect } from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import { BaseLayout } from '@layouts/base'
import '@styles/purchase.scss'
import axios, { AxiosResponse } from 'axios'
import clsx from 'clsx'

type DataProps = {
  site: {
    siteMetadata: {
      salesEmail: string
    }
  }
}

type SessionData = {
  success: boolean
  error?: string
  transactionId: string
  contactEmail: string
  billingEmail: string
}

const PurchaseCompletePage: React.FC<PageProps<DataProps>> = ({ data, location, navigate }) => {
  const salesEmail = data.site.siteMetadata.salesEmail
  const [sessionData, setSessionData] = useState<SessionData | undefined>()
  const sessionId = new URLSearchParams(location.search).get('session')

  useEffect(() => {
    const fetch = async () => {
      const response: AxiosResponse<SessionData> = await axios.get(
        '/api/deal/checkout-session-get',
        {
          params: { sessionId },
        }
      )

      if (response.data.success) {
        setSessionData(response.data)
      } else {
        navigate('/404')
      }
    }
    fetch()
  }, [])

  return (
    <BaseLayout title='Purchase Complete'>
      <section className='page-content'>
        <div className={clsx('postcard-layout', sessionData ? '' : 'blocked-page')}>
          <h1 className='page-content__title page-content__title--small'>
            Thank you for purchasing FullCalendar Premium!
          </h1>

          <table className='spacious-table'>
            <tbody>
              <tr>
                <th>Transaction ID:</th>
                <td>{sessionData?.transactionId}</td>
              </tr>
              <tr>
                <th>Instructions and receipt sent to:</th>
                <td>
                  <div>{sessionData?.contactEmail}</div>
                  {sessionData?.billingEmail !== sessionData?.contactEmail && (
                    <div>{sessionData?.billingEmail}</div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className='text-content'>
            <p>
              If you do not receive your confirmation emails in a timely fashion,
              <br />
              please first check your spam folder, then email {salesEmail}
            </p>
            <p>
              <Link to='/update-billing'>Update your tax ID or other billing information</Link>
            </p>
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        salesEmail
      }
    }
  }
`

export default PurchaseCompletePage
